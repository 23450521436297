.row-add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: none;
}

.enable-add-row {
  cursor: pointer !important;
  opacity: 1;
}

.disable-add-row {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.add-row-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #3C4C68;
  margin-left: 4.5px;
  background: none;
}