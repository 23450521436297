.workflowTabsContainer {
  height: var(--workflow-tabs-height);
  border-top: 1px solid var(--textFieldOutlineGray);
  border-bottom: 1px solid var(--textFieldOutlineGray);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-gap: 1.315rem; */
  margin-inline: 0.25rem;
  background-color: var(--white);
}

.documentContainer {
  display: flex;
  align-items: center;
  height: 2rem;
  gap: 1rem;
  margin-left: 1rem;
  border-right: 2px solid var(--textFieldOutlineGray);
}

.viewingLabelColor {
  color: var(--eyeIconColor);
}